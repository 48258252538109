import React, { createContext, useContext, useEffect, useState } from 'react'
import { Link, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import ScrollToTop from '../../helpers/ScrollToTop';
import { AllRoutes } from '../../routes/AllRoutes';
// import HomePage from '../homepage/HomePage';
import s from './nav_foo.module.css'
import { Context, ContextLogin, UserId, PrevUrl } from '../../helpers/context';
// import LoginPage from '../auth/LoginPage';
import axios from '../../helpers/Axios'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useRef } from 'react';
import ReactCountryFlag from "react-country-flag"
import { CircleFlag } from 'react-circle-flags'

function Navbar() {
  // let location = 'chennai'
  let navigate = useNavigate()
  let menu = useRef();
  let closeBtn = useRef();
  let loc = useRef()
  const [recive, setRecive] = useState([]);
  const [userdetails, setUserdetails] = useState({ phone: "", email: "", firstname: "", lastname: "", id: "" });
  const [user__name, setuser__name] = useState('')

  useEffect(() => {
    axios
      .get(`/user_information?contact=${localStorage.USER_PHONE_NO}`)
      .then((res) => {
        setRecive(res.data.user);
        setUserdetails({
          ...userdetails, phone: res.data.user[0].contact,
          email: res.data.user[0].email,
          firstname: res.data.user[0].firstname,
          lastname: res.data.user[0].lastname,
          id: res.data.user[0].id
        });
      })
    if (userdetails.id) {
      localStorage["USER_ID"] = userdetails.id
      localStorage["USER_firstname"] = userdetails.firstname
      localStorage["USER_email"] = userdetails.email
      localStorage["USER_lastname"] = userdetails.lastname
      localStorage["USER_PHONE_NO"] = userdetails.phone
    }

  }, []);

  function slideIn() {
    try {
      menu.current.classList.remove(`${s.slideOutLeft}`)
      menu.current.classList.add(`${s.slideInLeft}`)
    } catch (error) { }
    setTimeout(() => {
      menu.current.style = 'display:flex'
    }, 200);
  }

  function slideOut() {
    try {
      menu.current.classList.remove(`${s.slideInLeft}`)
      menu.current.classList.add(`${s.slideOutLeft}`)
    } catch (error) { }
    setTimeout(() => {
      menu.current.style = 'display:none'
    }, 200);
  }

  //context props
  const [state, setState] = useState();
  const [location, setLocation] = useState(1)
  const [isLogged, setIsLogged] = useState(false)
  const [userId, setUserId] = useState(null)
  const [prevUrl, setPrevUrl] = useState('/')
  //-----
  const [locList, setLocList] = useState([])
  const [loaded, setLoaded] = useState(false)
  const [showMenu, setShowMenu] = useState(false)
  const [popularLoc, setPopularLoc] = useState([]);
  const [unpopularLoc, setUnpopularLoc] = useState([]);
  const [firstloc, setFirstloc] = useState();
  const [locState, setLocState] = useState("Bangalore");
  const locationData = useLocation();




  const [showList, setShowList] = useState(true);
  const [showList1, setShowList1] = useState(false);
  const modules = ['Module 1', 'Module 2', 'Module 3', 'Module 4'];





  useEffect(() => {
    setState(location.state)
    let locationurl = window.location.pathname
    if (locationurl.includes('-')) {
      const regexPattern = /car-rental-in-(\w+)/;
      const match = locationurl.match(regexPattern);
      if (match && match[1]) {
        const location1 = match[1];
        // setLocation(data.id);
        async function getLocationList1() {
          await axios.get('/location')
            .then((res) => {
              let locationlist = res.data.location


              // console.log(locList, 'ccccccccccccc')
              locationlist.map((data) => {
                // alert('ffff')
                if (data.location === location1) {
                  //   alert('kkk')
                  setLocState(location1)
                  setLocation(data.id);
                  localStorage["City"] = location1
                  localStorage["locationID"] = data.id
                  // console.log(data.location, data.id, location1, 'ddd')
                }

              })
              // console.log(,'ddd')

              // console.log(location1, 'This will print '); // This will print "Chennai"
            })
            .catch((err) => { console.log(err); })
        }
        getLocationList1()
      } else {
        console.log("Location not found in the URL");
      }

    }
    // console.log(window.location.pathname, 'kkkkkkkkkkkkk')
    // if (locationData.pathname === '/') {
    //   setLocState(locState);
    // }
  }, [locationData]);
  useEffect(() => {
    async function getLocationList() {
      await axios.get('/location')
        .then((res) => { setLocList(res.data.location); setLoaded(true); })
        .catch((err) => { console.log(err); })
    }
    getLocationList();
  }, [])
  let nav = useNavigate()

  function locationforPopular() {
    axios
      .get(`/loc_popular`)
      .then((res) => {
        setPopularLoc(res.data);
        // setFirstloc(res.data[0].location)
        setLoaded(true)
        // console.log();
      })
      .catch((e) => {
        console.log(e,"Error");
      });
  }

  function locationforUnPopular() {
    axios
      .get(`/loc_unpopular`)
      .then((res) => {
        setUnpopularLoc(res.data);
        setLoaded(true)
        // console.log(res.data);
      })
      .catch(() => {
        console.log("Error");
      });
  }

  useEffect(() => {
    locationforPopular();
    locationforUnPopular();

  }, []);
  useEffect(() => {
    if (localStorage.USER_firstname) {
      setuser__name(localStorage.USER_firstname)
    } else if (sessionStorage.user) {
      // console.log(sessionStorage.user)
      setuser__name(sessionStorage.user)
    }
  });
  // console.log(user__name,'bbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbb')
  function handleLocationClick(data) {

    setLocation(data.id);
    setLocState(data.location)
    nav(`/luxury-car-rental-in-${data.location}`)
    // console.log(data)
  }


  function logout() {
    sessionStorage.user = ''
    localStorage["USER_PHONE_NO"] = ""
    localStorage["USER_ID"] = ""
    localStorage["USER_firstname"] = ""
    localStorage["USER_email"] = ""
    localStorage["USER_lastname"] = ""

    nav('/')
    window.location.reload();
  }
  localStorage["City"] = locState
  localStorage["locationID"] = location
 
  // console.log(userId);
  // console.log(location);
  // console.log(isLogged);
  // console.log(prevUrl);
  const isHomePage = window.location.pathname;
  // console.log(location, "&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&")
  const user = user__name
  let country=''
  return (

    <>

      <nav className={`${s.navbar}`}>
        <div className={`${s.navElements}`}>
          <div className={s.left}>
            {/* <button onClick={userdataget} >Get In Now</button> */}

            <span className={s.menu} style={{ cursor: 'pointer' }}
              onClick={() => {
                setShowMenu(true);
                slideIn();
                closeBtn.current.style = "visibility:visible"
              }}>
              <img src={require('../../assets/images/icons/flag-btn.png')} alt="Flag btn" width={24} height={24} />
            </span>
            <Link className={s.hide} to={'/'}>Luxury Cars</Link>
            <Link className={s.hide} to={'/yachts'}>Yachts</Link>
            <Link className={s.hide} to={'/jets_copters'}>Jets/Copters</Link>
          </div>
          <div className={s.middle}>
            <Link to={'/'}><img className={s.logo}src={require('../../assets/images/logo/WhatsApp Image 2024-04-08 at 20.37.51_00f46a33.jpg')} alt="Logo" width={200} height={180} style={{ paddingTop: '20px' }}  /></Link>
          </div>
          <div className={`${s.right}`}>
            <Link className={s.hide} to={'/contactus'}>Contact Us</Link>
            {/* <span style={{ cursor: 'pointer' }}><img src={require('../../assets/images/icons/search-icon.png')} alt="Search Icon" width={24} height={24} /></span> */}
            {/* <Link to={'login'}><div ><img src={require('../../assets/images/icons/uesrs-profile-icons.jpeg')} className={`${s.profileIcon} ${s.hide}`} alt="Logo" width={30} height={30}  /></div></Link> */}
            {user ? (

              <div className={`${s.myprofile} ${s.hide}`}>
                <details >
                  <summary className={s.myprofile__BV_toggle_}>{user__name}</summary>
                  <div className={s.name}>
                    <Link to={'profile/:id'} className={s.ilname} onClick={() => handleLocationClick()} >
                      Profile</Link><br></br>
                    <Link to={'profile/:id'} className={s.ilname} onClick={() => handleLocationClick()} >
                      History</Link><br></br>
                    <Link to={'/logout'} className={s.ilname} onClick={() => logout()} >
                      Log Out</Link>
                  </div></details>
              </div>
            ) : (<Link to={'/login'} className={`${s.ilname} ${s.hide}`} onClick={() => handleLocationClick()} >Sign in</Link>)}
            {isHomePage === `/luxury-car-rental-in-${localStorage.City}/` || isHomePage === `/self-drive-rental-in-${localStorage.City}/` || isHomePage === `/luxury-car-rental-in-${localStorage.City}` || isHomePage === `/self-drive-rental-in-${localStorage.City}` || isHomePage === `/` ? (
              <div className={`${s.location}`}>
                <div className={s.locationImg}></div>

                {/* <select name="location" className={s.btnSelect} onChange={(e) => { setLocation(e.target.value) }}>
                                {
                                    locList ? locList.map((data) => {
                                        return (<option key={data.id} value={data.id}>{data.location}</option>)
                                    }) : <></>
                                }
                            </select> */}

                <button
                  //   class="btn btn-primary"
                  className={s.btnSelect}
                  // className={location.pathname === '/' ? 'disabled' : 'active'}
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRight"
                  aria-controls="offcanvasRight"
                //   style={bgcolor}
                >
                  {locState}
                </button>

                <div
                  class={`${"offcanvas offcanvas-end bg-dark"} ${s.offcanvas}`}
                  // className={s.offcanvas}
                  tabindex="-1"
                  id="offcanvasRight"
                  aria-labelledby="offcanvasRightLabel"

                >
                  <div class="offcanvas-header">
                    
                    <div >
                    <div style={{display:'flex'}}>
                   
                    <button style={{'background-color': 'transparent',
                    'border': 'none',
                    'color': 'white'}} onClick={() => {setShowList(true)
                      setShowList1(false)}}>
                      
                      <CircleFlag countryCode="in" height="35" /><br/>India

            
                    </button>
                  
                    <button style={{'background-color': 'transparent',
                    'border': 'none',
                    'color': 'Gray'}} onClick={() => {setShowList1(true)
                    setShowList(false)}}>
                      
                        <div class="offcanvas-body" >
                          
                          {
                        popularLoc.map((data) => {
                          if (data.location === 'Dubai') {
                            country = data;
                          }
                          return (
                            <a
                              style={{ paddingBottom: "6px" }}
                              href=''
                              className={s.popularCities}
                              key={data.location}
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                              class="col-md-12 cursor-pointer"
                              onClick={() => { 
                                handleLocationClick(country);
                                setShowList1(!showList1);
                                setShowList(false);
                              }}
                              value={country.id}
                            >
                            {country.location?(
                             <div>
                             <CircleFlag countryCode="ae" height="35" /><br/>Dubai
                             </div>
                           ):(<></>)}
                            </a>
                          );
                        })}
                        </div>
                        
                      
                    </button>
                   
                    <button

                    type="button"
                    class="btn-close btn-close-white position-absolute top-0 end-0 mt-3 me-3"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"

                  ></button>
                  </div>
                
                 
                  
                   
                      <ul>
                      <div class="offcanvas-body">
                      <div class="row">
                        <p style={{ "font-weight": "bold", fontSize: 20 }}>Popular Cities</p>
  
                        {
                          popularLoc.map((data) => {
                            return (
                              <a style={{ paddingBottom: "6px" }} href='' className={s.popularCities}
                                key={data.location}
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                                class="col-md-12 cursor-pointer"
                                onClick={() => handleLocationClick(data)}
                                //   onChange={(e) => { setLocation(e.target.value) }}
                                value={data.id}
  
                              >{data.location!='Dubai'?(data.location):(<></>)}
                              
                              </a>
                            );
                          })}
                      </div>
                      <div class="row mt-3">
                        <p style={{ "font-weight": "bold", fontSize: 20 }}>Other Cities</p>
                        {unpopularLoc.map((data) => {
                          return (
                            <a style={{ paddingBottom: "6px" }} href='' className={s.popularCities}
                              key={data.id}
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                              class="col-md-12 cursor-pointer"
                              onClick={() => handleLocationClick(data)}
                              //   onChange={(e) => { setLocation(e.target.value) }}
                              value={data.id}
  
                            >
                              {data.location}
                            </a>
                          );
                        })}
                      </div>
                    </div>
                      </ul>
                    
                  </div>
                  
                  </div>
                  

                </div>

              </div>
            ) : (
              <div className={s.right}>
                <div className={`${s.location}`}>
                  <div className={s.locationImg}></div>
                  <button
                    //   class="btn btn-primary"
                    className={s.btnSelect}
                    // className={location.pathname === '/' ? 'disabled' : 'active'}
                    type="button"
                    // data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                  //   style={bgcolor}
                  >
                    {locState}
                  </button>
                </div> </div>)}
          </div>
        </div>
      </nav>
      {
        <div className='pages'>
          <ScrollToTop>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Context.Provider value={location}>
                <ContextLogin.Provider value={[isLogged, setIsLogged]}>
                  <UserId.Provider value={[userId, setUserId]}>
                    <PrevUrl.Provider value={[prevUrl, setPrevUrl]}>
                      <Routes>
                        {
                          AllRoutes.map((route) => {
                            return (
                              <Route key={route.id} path={route.path} element={route.element} />
                            )
                          })
                        }
                      </Routes>
                    </PrevUrl.Provider>
                  </UserId.Provider>
                </ContextLogin.Provider>
              </Context.Provider>
            </LocalizationProvider>
          </ScrollToTop>
        </div>
      }
      <div ref={menu} className={s.mobileMenu}>
        {localStorage.USER_PHONE_NO ? (
          <Link to={'/profile/:id'}><div style={{ color: 'white' }}><img src={require('../../assets/images/icons/Screenshot_3.png')} className={s.profileIcon} alt="Logo" onClick={() => { setShowMenu(false); slideOut(); }} width={40} height={40} />{localStorage.USER_firstname}</div></Link>) : (<Link to={'/login'}><div ><img src={require('../../assets/images/icons/uesrs-profile-icons.jpeg')} className={s.profileIcon} alt="Logo" onClick={() => { setShowMenu(false); slideOut(); }} width={30} height={30} /></div></Link>)}

        {/* <div ref={loc} className={`${s.location}`}>
          <div className={s.locationImg}></div>
          <select name="location" className={s.btnSelect} onChange={(e) => { setLocation(e.target.value); slideOut(); }}>
            {
              locList ? locList.map((data) => {
                return (<option key={data.id} value={data.id}>{data.location}</option>)
              }) : <></>
            }
          </select>
        </div> */}
        <Link to={'/'} onClick={() => { slideOut(); }}>Luxury Cars</Link>
        <Link to={'/yachts'} onClick={() => { slideOut(); }}>Yachts</Link>
        <Link to={'/jets_copters'} onClick={() => { slideOut(); }}>Jets/Copters</Link>
        <Link to={'/contactus'} onClick={() => { slideOut(); }}>Contact Us</Link>
        <div ref={closeBtn} className={s.closeBtn} onClick={() => { setShowMenu(false); slideOut(); }} style={{ color: 'white' }}>
          <img src={require('../../assets/images/icons/close-btn-white.png')} alt="Filter Icon" width={40} height={40}
            onClick={() => {
              slideIn();
              closeBtn.current.style = "visibility:hidden"
            }} />
        </div>
      </div>
    </>
  )
}

export default Navbar
